import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd'; // Si vous utilisez Ant Design pour des notifications, sinon vous pouvez utiliser un autre composant de notification
import AuthServices from '../services_hooks/AuthServices';
import { useLocalStorage } from '../services_hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { logUserOut } from '../services_hooks/auth_redux/authReducer';
import { useAppSelector } from '../services_hooks/auth_redux/auth_hooks';

const Deconnect: React.FC = () => {
  const navigate = useNavigate();
  const { removeItem } = useLocalStorage();
  const dispatch = useDispatch();


  useEffect(() => {
    // Fonction pour gérer la déconnexion
    const handleLogout = async () => {
      try {
        // Appeler la fonction de déconnexion depuis le service d'authentification
        await AuthServices.logout();
        message.success("Déconnecté avec succès !");

        // Effacer le stockage local (ou session) si nécessaire
        removeItem("prsa-usercredentials");
        dispatch(logUserOut());

        // Rediriger vers la page de connexion
        navigate('/login');
        
      } catch (error) {
        // En cas d'erreur lors de la déconnexion
        message.error("Une erreur s'est produite lors de la déconnexion");
      }
    };

    // Appeler la fonction de déconnexion lorsque le composant se monte
    handleLogout();
  }, []);

  return (<>
  </>);
};

export default Deconnect;
