import SateliteViewSvg from "../components/SateliteViewSvg";
import StreetViewSvg from "../components/StreetViewSvg";
import {
  DeploymentUnitOutlined,
  GlobalOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import country from "../core/departement.json";
import departNiger from "../core/departementsNiger.json";
import regionNiger from "../core/regionsNiger.json";


export const menuElementList: any[] = [
  { id: 1, name: "Distributions", link: "/distribution", icon: <DeploymentUnitOutlined /> },
  { id: 2, name: "Map", link: "/mapping", icon: <GlobalOutlined /> },
  { id: 3, name: "Dashboard", link: "/dashboard", icon: <ShareAltOutlined /> },
  // { id: 2, name: "Map", link: "/map", icon: <MapIcon /> },
];


export interface MapViewInterface {
  id: string;
  illustration: string;
  component?: any;
  viewName: string;
  tileLayerInfos: any;
}

export interface ChartDataTypeInterface {
  id: string;
  label: string;
  geojson: object;
  // List of divisions names where the project intervene
  interventionSet: any;
}

const departmentList:string[] = ['Abala', 'Abalak', 'Aderbissinat', 'Aguié', 'Arlit', 'Ayerou', 'Bagaroua', 'Balleyara', 'Banibangou', 'Bankilaré', 'Belbedji', 'Bermo', 'Bilma', "Birni N'Konni", 'Boboye', 'Bosso', 'Bouza', 'Dakoro', 'Damagaram Takaya', 'Diffa', 'Dioundiou', 'Dogondoutchi', 'Dosso', 'Dungass', 'Falmey', 'Filingué', 'Gaya', 'Gazaoua', 'Gothèye', 'Goudoumaria', 'Gouré', 'Guidan Roumdji', 'Iferouane', 'Illéla', 'Ingall', 'Kantché', 'Keita', 'Kollo', 'Loga', 'Madaoua', 'Madarounfa', 'Magaria', 'Malbaza', 'Mayahi', 'Maïné Soroa', 'Mirriah', "N'Gourti", "N'Guigmi", 'Ouallam', 'Say', 'TILLABERI', 'Tahoua', 'Takeita', 'Tanout', 'Tassara', 'Tchintabaraden', 'Tchirozerine', 'Tesker', 'Tessaoua', 'Tibiri', 'Tillia', 'Torodi', 'Téra', 'Ville de Maradi', 'Ville de Niamey', 'Ville de Tahoua', 'Ville de Zinder'];
export const TERRITORY_DIVISION_TYPES: ChartDataTypeInterface[] = [

  {
    id: "region",
    label: "Regions",
    geojson: regionNiger,
    interventionSet: ['Agadez', 'Diffa', 'Dosso', 'Maradi', 'Niamey', 'Tillabéri', 'Tahoua', 'Zinder'],
  },
  // {
  //   id: "departement",
  //   label: "Départements",
  //   geojson: departNiger,
  //   interventionSet: departmentList
  // },
];

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};


export const MAP_VIEWS: MapViewInterface[] = [
  {
    id: "1",
    illustration: "/street.jpg",
    component: <StreetViewSvg />,
    viewName: "Rues",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    component: <SateliteViewSvg />,
    viewName: "Satellite",
    tileLayerInfos: SATELITE_VIEW,
  },
];

/**
 * Reset height dynamically
 * @param heightSetter Funtion that update height variable
 * @returns 
 * 
 * When using  height=100vh we notice a scrolling behaviour on mobile.
 * Finally we decided to define the window height ourself by using window height changes.
 * Thus we need a listener to handle resizing in this situation.
 */
export const resizeWindow = function (heightSetter: any) {
  // Function to update window height
  const handleResize = () => heightSetter(window.innerHeight);

  // Listen to resize height after window size changes
  window.addEventListener('resize', handleResize);

  // Stop listening when component is destroyed
  return () => window.removeEventListener('resize', handleResize);
} 

