import { MAP_VIEWS } from "../core/constants";

export default function ModalSupperposition(props: any) {
  const { isOpen, onClose } = props;

  return (
    <div
      className={`overlay-inner absolute right-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div
        className="lc-modal absolute right-0 top-0 w-[200px] sm:w-[200px] bg-black/50 p-5 z-[999] transition-pacofide"
        data-lc-target="overlay"
      >
        <button
          className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
          data-lc-target="#overlay"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "currentColor" }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </button>
        <div className="space-y-12 max-h-[564px]">
          <div className="relative text-white">
            <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
              Superpositions
            </h3>
            <form className="mt-6">
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
                {MAP_VIEWS.map((view, index) => {
                  return (
                    <div className="" key={index}>
                      <input
                        id={view.id}
                        className="hidden peer/limites"
                        type="radio"
                        name="overlay"
                        checked={view.id === "limites"}
                        onChange={() => props.action(index)}
                      />
                      <label
                        htmlFor={view.id}
                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/limites:bg-blue-200 transition-pacofide"
                      >
                        <span className="flex items-center justify-center w-8 h-8 rounded-full">
                          {view.component}
                        </span>
                        <span
                          className={
                            "text-xs text-black " +
                            (props.currentIndex === index ? "font-bold" : "")
                          }
                        >
                          {view.viewName}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
