import React, { useEffect, useState } from 'react';
import { data, DataType } from '../data';
import { Col, Descriptions, DescriptionsProps, Row, Space, Table, TableProps, Tag, message } from 'antd';
import AddDist from '../components/distributions/ModalAdd';
import axios from '../services_hooks/axios';
import { Center } from '../models/CenterModel';
import { Farmer } from '../models/FarmerModel';
import { Farm } from '../models/FarmModel';
import ScanQRCode from '../components/distributions/ScanQRCode';
import { error } from 'console';
import { resizeWindow } from '../core/constants';


const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Centre',
    dataIndex: 'centre',
    key: 'centre',
  },
  {
    title: 'Ferme',
    dataIndex: 'ferme',
    key: 'ferme',
  },
  {
    title: 'Fermier',
    key: 'fermier',
    dataIndex: 'fermier',

  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <Space size="middle">
        <Tag color='green'>
          <a>Update</a>
        </Tag>
        <Tag color='processing'>
          <a>Delete</a>
        </Tag>
      </Space>
    ),
  },
];


const Distribution = () => {

  const [centers, setCenters] = useState<any>([]);
  const [farmers, setFarmers] = useState<any>([]);
  const [uuid, setUuid] = useState('');
  const [farmer, setFarmer] = useState<Farmer>()
  const [msgError, setMsgError] = useState("");
  const [isErrorUuid, setIsErrorUuid] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);

  const reinitUUID = ()=> {
    setUuid("");
  }


  useEffect(() => {
    resizeWindow(setHeight);
  }, []);

  useEffect(() => {
    Center.fetchData().then((response: any) => {
      setCenters(Center.fromJsonList(response.data.results));
    });
    Farmer.fetchData().then((response: any) => {
      setFarmers(Farmer.fromJsonList(response.data.results));
    });
  }, []);

  //Récupération des informations du fermier
  useEffect(() => {
    Farmer.getAFarmer(uuid).then((response: any) => {
      setMsgError("");
      setIsErrorUuid(false);
      setFarmer(Farmer.fromJson2(response.data));
    }).catch((error: any)=>{ 
      const msg=error.response.data.detail;
      if(error.response){
        if(msg === "No Farmer matches the given query.")
        setMsgError("Aucun fermier ne coorespond au code scanner");
        else
          setMsgError("Erreur lors de la recherche du fermier");
        setIsErrorUuid(true);
      }
      else{
        // Handle error
      }
    })
  }, [uuid]);


  const benef: DescriptionsProps['items'] = [
   
    {
      key: '1',
      label: 'Nom',
      children: (<>{farmer?.name}</>)
    },
    {
      key: '2',
      label: 'Prenoms',
      children: (<>{farmer?.firstname}</>)
    },
    {
      key: '3',
      label: 'Sexe',
      children: (<>{farmer?.sex}</>)
    },
  ]

  useEffect(() => {
    console.log(uuid);
  }, [uuid])

  return (
    <div className='container flex justify-center items-center' style={{
      height: `${height}px`,
    }}>
      <div className='w-4/5 mt-5 text-center' style={{
        margin: 'auto'
      }}>
        {/* <AddDist {...{farmer, farmers, centers }} /> */}
        {/* <Table<DataType> columns={columns} dataSource={data} /> */}
        {
          uuid && !isErrorUuid &&
          <>
            <Descriptions
              title="Bénéficiaire"
              items={benef}
            />
            <AddDist {...{ farmer, farmers, centers, rest: reinitUUID }} />
           
          </>
        }
        {
          (!uuid || isErrorUuid) &&
            <>
              <ScanQRCode uuidSetter={setUuid} />
              {
                isErrorUuid &&
                message.error(msgError)
              }
            </>
        }
        
      </div>
    </div>
  );
};

export default Distribution;