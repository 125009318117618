/* eslint-disable react/prop-types */
import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON } from "react-leaflet";
import { useCustomEventListener } from "react-custom-events";
import departNiger from "../../../core/departementsNiger.json";

// To be imported from backend
const layers = [];

export default function RegionSelector(props) {
  const { regions, interventionSet, filterMethod, statsUnitSetter } = props;

  const commonStyle = {
    color: "black",
    weight: 1.3,
  };
  const interventionStyle = {
    ...commonStyle,
    fillColor: "yellow",
    fillOpacity: 1,
  };
  const deselectedStyle = {
    ...commonStyle,
    fillColor: "gray",
    fillOpacity: 0.3,
  };
  const neutralStyle = {
    ...commonStyle,
    fillColor: "white",
    fillOpacity: 1,
  };

  const setInitialStyle = (layer) => {
    if (interventionSet.indexOf(layer.feature.properties.Nom) !== -1) {
      layer.setStyle(interventionStyle);
    } else {
      layer.setStyle(neutralStyle);
    }
  };

  useCustomEventListener("re-init", () => {
    layers.forEach((layer) => {
      setInitialStyle(layer);
    });
  });

  const onEachFeature = (feature, layer) => {
    console.log("INTER ", feature.properties.adm_01);
    console.log("INTER DEP ", feature.properties.departement);
    if (interventionSet.indexOf(feature.properties.adm_01) !== -1 || interventionSet.indexOf(feature.properties.departement) !== -1) {
      console.log("INTER YEN A ", interventionSet);

      layer.on({
        click: () => {
          layer.setStyle(interventionStyle);
          layers.forEach((element) => {
            console.log("LAY ELEME ", element);
            if (element.feature.properties.adm_01 !== feature.properties.adm_01 || element.feature.properties.departement !== feature.properties.departement) {
              if (element.options.fillColor === interventionStyle.fillColor) {
                element.setStyle(neutralStyle);
              }
            }
          });
          if(statsUnitSetter)
          {
            statsUnitSetter('departement');
          }
          filterMethod(feature.properties.adm_01 ?? feature.properties.departement);
        },
      });
    }
    setInitialStyle(layer);
    layers.push(layer);
  };


  const bounds = L.geoJSON(departNiger).getBounds();
  return (
    <MapContainer
      bounds={bounds}
      dragging={false}
      zoomControl={false}
      zoom={12}
      doubleClickZoom={false}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "100%" }}
    >
      <GeoJSON data={regions} onEachFeature={onEachFeature} />
    </MapContainer>
  );
}
