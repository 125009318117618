/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getFromApiFunction } from "../../../services_hooks/apiFunction";
import { Alert } from "antd";
import { barChatOption, transformToDataSet } from "../../../core/helpers";
import Loading from "../../../components/Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
);

export const tempOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    backgroundColor: "#ebab34",
  },
};

export default function BarChart(props: any) {
  const {
    graphName,
    baseEndpoint,
    statsUnitProps,
    paramsProps,
    buildParameter,
    color,
    customHeigth,
    statsUnitSetter,
  } = props;
  const [chartData, setChartData] =
    useState<ChartData<"bar", (number | [number, number] | null)[], unknown>>();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [statsUnit, setStatsUnit] = useState(statsUnitProps);
  const [params, setParams] = useState(paramsProps);

  const firstRender = useRef(true); //To check if component is mounted

  const getChartData = async () => {
    setApiCalling(true);
    setHasError(false);
    let endpoint = statsUnit;
    await getFromApiFunction(`${baseEndpoint}by_${endpoint}/`, params)
      .then((response: any) => {
        setChartData(transformToDataSet(graphName, response.data, color));
        setApiCalling(false);
      })
      .catch((error: any) => {
        setHasError(true);
        setApiCalling(false);
      });
  };

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {
    setParams(paramsProps);
  }, [paramsProps]);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //
  //     if (params && params.keys().length > 0) {
  //
  //       setStatsUnit("commune");
  //     } else {
  //
  //     }
  //   }
  // }, [params]);

  const handleBarClick = (event: any, chartElements: any) => {
    // if (chartElements.length > 0) {
    //   const index = chartElements[0].index;
    //   const labels = chartData?.labels;
    //   if (labels) {
    //     const label: any = labels[index];
    //     buildParameter(label);
    //     const params = new URLSearchParams();
    //     params.append(statsUnit, label);
    //     setParams(params);
    //   }
    // }
  };

  useEffect(() => {
    getChartData();
  }, [statsUnit, params]);

  return (
    <>
      {apiCalling && <Loading />}
      {
        !apiCalling && hasError && <div className="w-fit">
          <Alert
            message="Une erreur s'est produite"
            showIcon
            description="Veuillez réessayer"
            type="error"
          />
        </div>
      }
      {!apiCalling && !hasError && (
        <Bar
          options={barChatOption(graphName, handleBarClick)}
          height={customHeigth ? `"${customHeigth}px"` : "300px"}
          data={
            chartData as ChartData<"bar", (number | [number, number] | null)[], unknown>
          }
          className="w-fit h-fit"
        />
      )}
    </>
  );
}
