import { Tabs, TabsProps } from "antd";
import TdbComponent from "./TdbComponent";

const items: TabsProps['items'] = [
  {
    key: 'distribution',
    label: 'Distributions',
  },
  {
    key: 'intrants',
    label: 'Intrants',
  },
];

export default function HomePage() {
  

  return (
    <div className="h-full w-full">
      <TdbComponent elementToGet={'distribution'} />
      {/* <Tabs
        defaultActiveKey="1"
        centered
        items = {
          items?.map((item) => {
            return {
              key: item.key,
              label: item.label,
              children: <TdbComponent elementToGet={item.key} />
            };
          })
        }
        
      /> */}
    </div>
  );
}
