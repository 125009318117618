import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';

import { Button, message } from 'antd';

const ScanQRCode = (parameters: any) => {
  const { uuidSetter } = parameters;
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [showScanner, setShowScanner] = useState(false);
  const [shouldScan, setShouldScan] = useState(false);


  // function call after QR Code is detected
  const handleScan = (data: any) => {
    if (data) {
      setScanResult(data[0].rawValue); 
      uuidSetter(data[0].rawValue);
      setShowScanner(false); 
    }
  };

  // function call after error is detected
  const handleError = (err: any) => {
    
  };

  // Fonction pour envoyer la requête avec le code scanné

  return (
    <>
      <Button
        className='bg-primary flex flex-col m-auto'
        type="primary" onClick={() => showScanner ? setShowScanner(false) : setShowScanner(true)}>
        <span>
          {showScanner ? 'Fermer le Scanner' : " Scanner un QR Code"}
        </span>
      </Button>

      {/* Display scanner on button click */}
      {/*
        On development mode run server with the following to enable camera on mobile
        `HTTPS=true npm start`
      */}
      {showScanner && (
        <Scanner onScan={handleScan} constraints={{
          facingMode: "environment",
          width: 400,
          height: 400,
          frameRate: 30
        }} />
      )}
    </>
  );
};

export default ScanQRCode;
